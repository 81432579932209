import React, { useState } from 'react'

const ReadMore = ({ children }) => {
    const [readMore, setReadMore] = useState(false)


    const toggleRea = () => {
        setReadMore(!readMore)
    }

    return (
        <div className='text_justify'>
            {readMore ? children : children.substr(0, 800)} <br />
            {!readMore ? <button onClick={toggleRea} type="button" className="mb-3 mt-1 text-white btn btn-warning">Read More</button>
                : <button onClick={toggleRea} type="button" className="mb-3 mt-1 text-white btn btn-warning">Read Less</button>
            }
        </div>
    )
}

export default ReadMore
