import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const ProcessServices = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="background-service-home">
        <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">Data Privacy Process Re-engineering and Alignment</h1>
      </div>
      <div className='container py-5'>


        <div className='row'>
          <div className='col-md-8 mb-4'>
            <h2 class="accent font mb-3">Data Privacy Process Re-engineering and Alignment </h2>
            <p className='pe-lg-5 text_justify'>Data Privacy Process Re-engineering and Alignment involves a strategic overhaul of an organization's data handling and privacy procedures to ensure they are not only compliant with current data protection laws but also optimized for efficiency and effectiveness. This initiative focuses on analyzing and redesigning the processes related to data collection, storage, processing, and sharing, to enhance data security and privacy while minimizing risks and inefficiencies. By aligning these processes with both regulatory requirements and best practices, organizations can achieve a more robust data privacy framework. This approach not only protects sensitive information but also builds trust with stakeholders, demonstrating a commitment to responsible data management. Through continuous improvement and adaptation to evolving data protection landscapes, businesses can maintain agility and resilience against privacy-related challenges.</p>
          </div>
          <div className='col-md-4 mb-4'>
            <div className='card card_shodow1 border-0'>
              <h5 className='card-header bg_data_ul py-3 '>Data Privacy Services</h5>
              <div className='card-body'>
                <ul className='mb-0'>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'>Initial Data Privacy Programme Implementation </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-officer' className='clr_data_list'>The Outsourced Data Protection Officer services </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-advisory' className='clr_data_list'>The General Support, Consulting and Advisory Service </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-security' className='clr_data_list'>The Security and Technical Privacy Services </Link></li>
                  <li className='f14  marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-organisational' className='clr_data_list'>Organisational and Executive Privacy Training Development </Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessServices
