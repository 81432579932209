import React, { useState } from 'react';
import Swal from 'sweetalert2';

const Index = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        date: '',
        consultationChecked: false
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const formatDate = (date) => {
        const parts = date.split('-');
        return `${parts[1]}/${parts[2]}/${parts[0]}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0 && formData.consultationChecked) {
            try {
                const response = await fetch('https://development81.shrinkcom.com/smtp_mailsss/send-mail-uci.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'appointment',
                        name: formData.fullName,
                        email: formData.email,
                        phone: formData.phoneNumber,
                        date: formatDate(formData.date) 
                    })
                });
                if (response.status) {
                    Swal.fire({
                        text: 'Appointment booked successfully',
                        icon: 'success',
                        confirmButtonText: 'Yes',
                        confirmButtonColor: '#e8b923',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                } else {
                    console.error('Failed to book appointment');
                }
            } catch (error) {
                console.error('Error occurred while booking appointment:', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.fullName.trim()) {
            errors.fullName = 'Name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone number is required';
        } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
            errors.phoneNumber = 'Phone number must be 10 digits';
        }
        if (!formData.date) {
            errors.date = 'Date is required';
        }   
        if (!formData.consultationChecked) {
            errors.consultationChecked = 'Please check the box to book the consultation';
        }
        return errors;
    };

    return (
        <div className='container py-5' >
            <div className='row'>
                <div className='col-12'>
                    <div className='card card_shadow1 '>
                        <h3 className='font card-header border-0 text-center bg_data_ul'>Details</h3>
                        <div className='card-body'>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-6 mb-4'>
                                        <div className="appointment">
                                            <label htmlFor="fullName" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="fullName" name="fullName" placeholder='Enter Name' value={formData.fullName} onChange={handleChange} />
                                            {errors.fullName && <span className="text-danger">{errors.fullName}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <div className="appointment">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder='Enter Email' value={formData.email} onChange={handleChange} />
                                            {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <div className="appointment">
                                            <label htmlFor="phoneNumber" className="form-label">Phone</label>
                                            <input type="tel" className="form-control" id="phoneNumber" name="phoneNumber" placeholder='1234567890' value={formData.phoneNumber} onChange={handleChange} />
                                            {errors.phoneNumber && <span className="text-danger">{errors.phoneNumber}</span>}
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-4'>
                                        <div className='appointment'>
                                            <label htmlFor="date" className="form-label">Date</label>
                                            <input type="date" className='form-control' id="date" name="date" value={formData.date} onChange={handleChange} />
                                            {errors.date && <span className="text-danger">{errors.date}</span>}
                                        </div>
                                    </div>
                                    <div className='col-12 mt-3'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="form-check mb-3">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="consultationChecked"
                                                        name="consultationChecked"
                                                        checked={formData.consultationChecked}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="consultationChecked">
                                                        Book your one hour consultation
                                                    </label>
                                                    {errors.consultationChecked && <span className="text-danger">{errors.consultationChecked}</span>}
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <button type="submit" className="mb-3 mt-2 text-white btn btn-warning px-4">Book</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Index;
