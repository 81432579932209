import React from 'react'
import img from '../../assets/blog-slider.jpg'
import img1 from '../../assets/contact.webp'
import img2 from '../../assets/blog1.jpg'
import img3 from '../../assets/blog4.avif'
import img4 from '../../assets/blog2.jpg'
import img5 from '../../assets/blog3.jpg'
import { Link } from 'react-router-dom'


const BlogList = () => {
  return (
    <div>
      <div className="background_blog">
        <div className="">
          <h1 className="text-center mb-0 fs5-3 accent blog_slider_head">Blog</h1>
        </div>
      </div>
      <div className="container py-4">

        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="de-card-poster">
              <div className="d-overlay" >
                <div className="d-content">
                  <h3 className='d-content_h3'>Navigating the Data Privacy Maze: Your Ultimate Guide </h3>
                  <div className="d-text">
                  Welcome to the United Consulting International Limited blog—a space dedicated to unravelling the intricacies of data privacy and helping businesses navigate the ever-evolving landscape of digital security. In our inaugural post, we embark on a journey through the essential aspects of data privacy, shedding light on why it matters now more than ever.
                  </div>
                  <Link to='/allblog/blogdetail' className="btn-main">Read More</Link>
                </div>
              </div>
              <div className="d-image blog_bg_img1" ></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="de-card-poster">
              <div className="d-overlay" >
                <div className="d-content">
                  <h3 className='d-content_h3'>Lorem Ipsum </h3>
                  <div className="d-text">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </div>
                   <Link to='/allblog/blogdetail' className="btn-main">Read More</Link>
                </div>
              </div>
              <div className="d-image blog_bg_img2" ></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="de-card-poster">
              <div className="d-overlay" >
                <div className="d-content">
                  <h3 className='d-content_h3'>Lorem Ipsum </h3>
                  <div className="d-text">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </div>
                   <Link to='/allblog/blogdetail' className="btn-main">Read More</Link>
                </div>
              </div>
              <div className="d-image blog_bg_img3" ></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="de-card-poster">
              <div className="d-overlay" >
                <div className="d-content">
                  <h3 className='d-content_h3'>Lorem Ipsum </h3>
                  <div className="d-text">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </div>
                   <Link to='/allblog/blogdetail' className="btn-main">Read More</Link>
                </div>
              </div>
              <div className="d-image blog_bg_img4" ></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="de-card-poster">
              <div className="d-overlay">
                <div className="d-content">
                  <h3 className='d-content_h3'>Lorem Ipsum </h3>
                  <div className="d-text">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </div>
                   <Link to='/allblog/blogdetail' className="btn-main">Read More</Link>
                </div>
              </div>
              <div className="d-image blog_bg_img5" ></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="de-card-poster">
              <div className="d-overlay" >
                <div className="d-content">
                  <h3 className='d-content_h3'>Lorem Ipsum </h3>
                  <div className="d-text">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                  </div>
                   <Link to='/allblog/blogdetail' className="btn-main">Read More</Link>
                </div>
              </div>
              <div className="d-image blog_bg_img1" ></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default BlogList
