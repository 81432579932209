import React from 'react'
import img1 from '../../assets/ind-7.png';

const IndustryCharities = () => {
    return (
        <div className='min-height_tab'>
            <div className='industry_slider'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-12'>
                            <h1 className="fs5-3 accent mb-0 blog_slider_head text-center text-white">Charities/Churches (Non-profit Organizations)</h1>

                        </div>
                    </div>
                </div>
                <svg id="opt_5" data-name="opt 5" xmlns="http://www.w3.org/2000/svg" width="100%" height="64" viewBox="0 0 1366 64" preserveAspectRatio="none" className='svg_slider'>
                    <defs>
                        <style>{`.cls-1{fill:currentColor;fill-rule:evenodd;}`}</style>
                    </defs>
                    <g id="Wave-7">
                        <path id="Rectangle" className="cls-1" d="M0,61.49C623,61.49,699,13.1117,1366,0V64H0Z"></path>
                    </g>
                </svg>

            </div>
            <div className='container py-5'>
               
                <div className='row'>
                    <div className='col-md-3 col-xxl-2 align-items-center col-md-3 d-flex justify-content-center mb-4 mb-md-0'>
                        <div className='bg-warning-ind'>
                            <img src={img1} className='img_ind' />
                        </div>
                    </div>
                    <div className='col-md-9 col-xxl-10'>
                    <div className='industry_content '>
                    <h2 class="accent font mb-3">Charities/Churches (Non-profit Organizations)
                         </h2>
                    <p className='text_justify'>Non-profit organizations in Jamaica, handling a diverse range of sensitive information, including donor records and financial data, must adhere to the Jamaica Data Protection Act, 2020, and relevant regulations governing non-profit entities. United Consulting International understands the importance of maintaining donor trust and tailors its data privacy approach to address the unique requirements of non-profit entities in Jamaica. Our strategies include implementing robust security measures to protect donor confidentiality, ensuring compliance with the Data Protection Act, and providing guidance on ethical data handling practices. We aim to create a secure environment that instills confidence in donors while respecting the principles of transparency and accountability.</p>
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryCharities
