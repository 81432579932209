import React from 'react'
import img1 from '../../assets/ind-2.png';

const IndustryMedical = () => {
    return (
        <div className='min-height_tab'>
            <div className='industry_slider'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className="fs5-3 accent mb-0 blog_slider_head text-center text-white">Medical</h1>

                        </div>
                    </div>
                </div>
                <svg id="opt_5" data-name="opt 5" xmlns="http://www.w3.org/2000/svg" width="100%" height="64" viewBox="0 0 1366 64" preserveAspectRatio="none" className='svg_slider'>
                    <defs>
                        <style>{`.cls-1{fill:currentColor;fill-rule:evenodd;}`}</style>
                    </defs>
                    <g id="Wave-7">
                        <path id="Rectangle" className="cls-1" d="M0,61.49C623,61.49,699,13.1117,1366,0V64H0Z"></path>
                    </g>
                </svg>

            </div>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-md-3 col-xxl-2 align-items-center col-md-3 d-flex justify-content-center mb-4 mb-md-0'>
                        <div className='bg-warning-ind'>
                            <img src={img1} className='img_ind' />
                        </div>
                    </div>
                    <div className='col-md-9 col-xxl-10'>
                        <div className='industry_content'>
                            <h2 class="accent font mb-3">Medical Industry </h2>
                            <p className='text_justify'>In the Jamaican medical field, the privacy and security of patient information are paramount, aligning with the Jamaica Data Protection Act, 2020 and other relevant healthcare legislation. United Consulting International acknowledges the intricacies of healthcare data and tailors its data privacy approach to meet the unique demands of the medical industry in Jamaica. Our strategies encompass the implementation of stringent security measures, ensuring compliance not only with the Data Protection Act but also with sector-specific regulations. We prioritize the confidentiality of patient records, establish secure data transmission channels, and provide ongoing training to healthcare staff to uphold the highest standards of data protection.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryMedical
