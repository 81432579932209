import React from 'react'
import img1 from '../../assets/ind-6.png';

const IndustryFinancial = () => {
    return (
        <div className='min-height_tab'>
            <div className='industry_slider'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-12'>
                            <h1 className="fs5-3 accent mb-0 blog_slider_head text-center text-white">Financial</h1>

                        </div>
                    </div>
                </div>
                <svg id="opt_5" data-name="opt 5" xmlns="http://www.w3.org/2000/svg" width="100%" height="64" viewBox="0 0 1366 64" preserveAspectRatio="none" className='svg_slider'>
                    <defs>
                        <style>{`.cls-1{fill:currentColor;fill-rule:evenodd;}`}</style>
                    </defs>
                    <g id="Wave-7">
                        <path id="Rectangle" className="cls-1" d="M0,61.49C623,61.49,699,13.1117,1366,0V64H0Z"></path>
                    </g>
                </svg>

            </div>
            <div className='container py-5'>
                
                <div className='row'>
                    <div className='col-md-3 col-xxl-2 align-items-center col-md-3 d-flex justify-content-center mb-4 mb-md-0'>
                        <div className='bg-warning-ind'>
                            <img src={img1} className='img_ind' />
                        </div>
                    </div>
                    <div className='col-md-9 col-xxl-10'>
                    <div className='industry_content'>
                    <h2 class="accent font mb-3">Financial Industry </h2>
                    <p className='text_justify'>The financial industry in Jamaica, dealing with a wealth of sensitive financial information, demands a meticulous and adaptive data privacy strategy aligned with the Jamaica Data Protection Act, 2020, and other relevant financial regulations. United Consulting International recognizes the dynamic nature of financial data and tailors its approach to the unique challenges faced by financial institutions in Jamaica. Our strategies encompass the implementation of robust security measures, ensuring compliance not only with the Data Protection Act but also with sector-specific regulations. We focus on safeguarding customer information, securing financial transactions, and continuously adapting to emerging threats to maintain the highest level of data security.</p>
                </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryFinancial
