import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import img from '../../assets/appointment1.jpg'

const MakeAppointment = () => {
    return (
        <div>
            <div className=''>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                           <div className='align-items-center d-flex h-100 justify-content-center my-auto'> <img src={img} className='img-fluid' /></div>
                        </div>
                        <div className='col-md-6 ps-lg-5'>
                            <div className='card_ap card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div class="mb-3 appointment">
                                                <label for="Name" className="form-label"> Name</label>
                                                <input type="text" className="form-control form-control" id="fullName" placeholder='Enter Name' />
                                            </div>
                                            <div className="mb-3 appointment">
                                                <label for="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="email" placeholder='Enter Email' />
                                            </div>
                                            <div className="mb-3 appointment">
                                                <label for="phone" className="form-label">Phone</label>
                                                <input type="number" className="form-control" id="number" placeholder='1234567890' />
                                            </div>
                                        </div>
                                        <div className='mb-3 appointment'>
                                            <label for="date" className="form-label">Date</label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DatePicker className='form-control date_input' />
                                            </LocalizationProvider>
                                        </div>
                                        <div>
                                            <button type="button" class="mb-3 mt-1 text-white btn btn-warning">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MakeAppointment;
