import React, { useState } from 'react';
import { RiBuilding4Line } from 'react-icons/ri';
import { MdOutlineEmail, MdOutlinePhoneInTalk } from 'react-icons/md';
import Swal from 'sweetalert2';
import axios from 'axios';

const ContactUs = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: '',
        phone: ''
    });

    const handleChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSend = () => {
        // Form validation
        if (!formData.fullName || !formData.email || !formData.message) {
            Swal.fire({
                text: 'Please fill in all required fields.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            });
            return;
        }

        // API call for sending email
        axios.post('https://development81.shrinkcom.com/smtp_mailsss/send-mail-uci.php', {
            type: 'contactUs',
            name: formData.fullName,
            email: formData.email,
            message: formData.message
        })
        .then(response => {
            Swal.fire({
                text: 'I willingly give my permission for the use of my information for the delivery of services to me, in compliance with applicable data protection laws.',
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        })
        .catch(error => {
            console.error('Error sending message:', error);
            Swal.fire({
                text: 'Failed to send message. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        });
    };

    const handleCallbackRequest = () => {
        // Form validation
        if (!formData.fullName || !formData.phone || !isChecked) {
            Swal.fire({
                text: 'Please fill in all required fields.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            });
            return;
        }

        // Phone number validation
        if (formData.phone.toString().length !== 10) {
            Swal.fire({
                text: 'Please enter a 10-digit contact number.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            });
            return;
        }

        // API call for callback request
        axios.post('https://development81.shrinkcom.com/smtp_mailsss/send-mail-uci.php', {
            type: 'callRequest',
            name: formData.fullName,
            phone: formData.phone,
            message: formData.message
        })
        .then(response => {
            Swal.fire({
                text: 'I willingly give my permission for the use of my information for the delivery of services to me, in compliance with applicable data protection laws.',
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        })
        .catch(error => {
            console.error('Error sending callback request:', error);
            Swal.fire({
                text: 'Failed to send callback request. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#e8b923',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            }); 
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <div>
            <div className="background-contactus">
                <div className="layer-contactus">
                    <div className='container my-auto' >
                        <h1 className='fs5-3 text-white text-center mb-3' >Contact Us</h1>
                        <div className='row gx-4' >
                            <div className='col-md-8 col-lg-6 mx-auto' >
                                <div className='contactus-form mx-auto p-3'>
                                    <div className="mb-3">
                                        <label htmlFor="fullName" className="form-label">Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fullName"
                                            placeholder='Enter Full Name'
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder='Enter Email'
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows="3"
                                            placeholder='Enter Message'
                                            name="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                    <button
                                        type="button"
                                        className="mb-3 mt-1 text-white btn btn-warning"
                                        onClick={handleSend}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='container my-6' >
                    <div className='row' >
                        <div className='col-md-7 col-lg-7'>
                            <div className='row'>
                                <div className='col-md-12 mb-4' >
                                    <div className='contactus-details' >
                                        <div className='contact-icon-ring mx-3' >
                                            <RiBuilding4Line className='contact-icon accent' />
                                        </div>
                                        <p className='contact-detail' >UNITED CONSULTING INTERNATIONAL LTD</p>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-4' >
                                    <div className='contactus-details' >
                                        <div className='contact-icon-ring mx-3' >
                                            <MdOutlineEmail className='contact-icon accent' />
                                        </div>
                                        <p className='contact-detail ' >info@uciconsult.com</p>
                                    </div>
                                </div>
                                <div className='col-md-12 mb-4' >
                                    <div className='contactus-details' >
                                        <div className='contact-icon-ring mx-3' >
                                            <MdOutlinePhoneInTalk className='contact-icon accent' />
                                        </div>
                                        <p className='contact-detail' >(876) 239-1727</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-5'>
                            <div className='card card_shodow1'>
                                <div className='card-body'>
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label"> Name</label>
                                        <input
                                            type="text"
                                            className="form-control-request "
                                            id=""
                                            placeholder='Enter Full Name'
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">Contact</label>
                                        <input
                                            type="number"
                                            className="form-control-request "
                                            id=""
                                            placeholder='123 456 7890'
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-check mb-3">
                                            <input
                                                className="form-check-input input_check"
                                                type="checkbox"
                                                value=""
                                                id="flexCheckDefault"
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label fs14" htmlFor="flexCheckDefault">
                                                I'm interested in discussing
                                            </label>
                                        </div>
                                        {isChecked && (
                                            <textarea
                                                className="form-control-request"
                                                placeholder="type..."
                                                id=""
                                                name="message"
                                                value={formData.message}
                                                onChange={handleInputChange}
                                            ></textarea>
                                        )}
                                    </div>
                                    <button
                                        type="button"
                                        className=" mt-1 text-white btn btn-warning"
                                        onClick={handleCallbackRequest}
                                        disabled={!isChecked}
                                    >
                                        Request a Callback
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;
