import React from 'react'
import { GiTeacher } from "react-icons/gi";
import { GrSecure } from "react-icons/gr";
import { FaGraduationCap } from "react-icons/fa";
import { GoTasklist } from "react-icons/go";
import { LuMonitor } from "react-icons/lu";
import { RiNumber1, RiNumber3, RiNumber4, RiNumber5, RiNumber6 } from "react-icons/ri";
import { RiNumber2 } from "react-icons/ri";






const Overview = () => {
  return (
    <div className='container-fluid my-4 px-0' >
      <div className='container' >
        <h2 className="accent font" >Organisational Overview</h2>
        <p className='text_justify'> United Consulting International Limited is a full-service consulting organization. We take clients from the ideation stage of their business, to establishing structured enterprises, built on sound corporate governance, profits and sustainability. We guide companies beyond registration and take a hand-holding approach in explaining the technicalities in operating a business. </p>
        <p className='text_justify'> An important service provided is data privacy management and consultation, as this is critical to overall business operations and profitability of any company. With the institution of the new Data Protection Act, 2020 we appreciate that many companies are not equipped with the expertise to implement the requisite strategies and become compliant with the legislative requirements of the DPA. We have therefore embarked on a mission to provide the skills and expertise required so assist companies in this regard.  </p>
        <p className='text_justify'>As data protection and privacy experts, our data protection efforts have been effective in many aspects, including encryption protocols, access controls, data mapping and drafting privacy policies specific to organizational needs. We guide companies throughout the full life cycle of the data protection process, building trust with internal and external stakeholders, as well as safeguarding your company’s assets. We collaborate with key players across industries to facilitate and encourage compliance, as well as ensure that our clients meet industry standards. Within problems, we see opportunities and we take a solutions-based approach to issues that impact the operations of companies.   </p>
      </div>

      <div className='container-fluid px-0 my-6' >

        <h1 className='font text-center mb-3' >We Will</h1>
        <div id="content">
          <div className="align-items-center d-flex flex-column gap-1 justify-content-center py-4 small text-center" style={{ backgroundColor: "#b8860b" }} >
            <GiTeacher className='we_will_icon text-white mb-2' />
            <h4 className='text-white f18_tab' >Educate</h4>
          </div>
          <div className="align-items-center d-flex flex-column gap-1 justify-content-center py-4 small text-center" style={{ backgroundColor: "#cc9900" }} >
            <GrSecure className='we_will_icon text-white mb-2' />
            <h4 className='text-white f18_tab' >Secure Your Data</h4>
          </div>
          <div className="align-items-center d-flex flex-column gap-1 justify-content-center py-4 small text-center" style={{ backgroundColor: "#ecbd00" }} >
            <FaGraduationCap className='we_will_icon text-white mb-2' />
            <h4 className='text-white f18_tab' >Train</h4>
          </div>
          <div className="align-items-center d-flex flex-column gap-1 justify-content-center py-4 small text-center" style={{ backgroundColor: "#ffcc00" }} >
            <GoTasklist className='we_will_icon text-white mb-2' />
            <h4 className='text-white f18_tab' >Manage Compliance</h4>

          </div>
          <div className="align-items-center d-flex flex-column gap-1 justify-content-center py-4 small text-center" style={{ backgroundColor: "#ffdf00" }} >
            <LuMonitor className='we_will_icon text-white mb-2' />
            <h4 className='text-white f18_tab' >Monitor</h4>
          </div>
        </div>

      </div>

      <div className='container my-5' >

        <h3 className='mb-5' >Key Objectives:</h3>

        <ul className='list p-0' >

          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber1 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Compliance Assurance:</h5>
              <p className='text-secondary m-0' >Ensure full compliance with existing data protection laws, and prepare for upcoming regulations. </p>
            </div>
          </li>

          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber2 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Risk Mitigation:</h5>
              <p className='text-secondary m-0' >Identify and mitigate data security risks through regular risk assessments, vulnerability testing, and proactive monitoring.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber3 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Data Governance Enhancement:</h5>
              <p className='text-secondary m-0' >Strengthen data governance practices to ensure data accuracy, transparency, and accountability.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber4 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >User Privacy:</h5>
              <p className='text-secondary m-0' >Enhance user privacy by providing clear and concise data collection and usage policies and implementing robust consent management mechanisms.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber5 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Data Breach Response:</h5>
              <p className='text-secondary m-0' >Develop and implement a well-defined incident response plan to minimize the impact of potential data breaches.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber6 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Employee Training:</h5>
              <p className='text-secondary m-0' >Conduct comprehensive data protection and privacy training programs for all employees to ensure awareness and compliance.</p>
            </div>
          </li>
        </ul>
      </div>

      <div className='container my-5' >
        <h3>Proposed Actions:</h3>
        <p className='text-secondary mb-5' >To achieve these objectives, we recommend the following actions:</p>
        <ul className='p-0' >
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber1 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Appointment of a Data Protection Officer (DPO):</h5>
              <p className='text-secondary m-0' >Designate a DPO responsible for ensuring compliance with data protection regulations and overseeing all data protection activities.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber2 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Regular Audits and Assessments:</h5>
              <p className='text-secondary m-0' >Conduct regular data protection audits and risk assessments to identify vulnerabilities and areas for improvement.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber3 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Data Classification and Inventory:</h5>
              <p className='text-secondary m-0' >Develop a comprehensive data classification system and maintain an accurate inventory of all data assets.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber4 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Privacy by Design:</h5>
              <p className='text-secondary m-0' >Incorporate privacy considerations into the development of all new systems, applications, and processes.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber5 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Data Mapping:</h5>
              <p className='text-secondary m-0' >Create a detailed data map to track the flow of data within the organization, facilitating transparency and accountability.</p>
            </div>
          </li>
          <li className='mb-4 d-flex'>
            <div className='number-icon' >
              <RiNumber6 />
            </div>
            <div className='key-points' >
              <h5 className='m-0' >Incident Response Plan:</h5>
              <p className='text-secondary m-0' >Develop and test an incident response plan to respond effectively to data breaches and minimize their impact.</p>
            </div>
          </li>
        </ul>
      </div>

    </div>
  )
}

export default Overview
