import React from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineKeyboardDoubleArrowRight  } from "react-icons/md";

const NavbarTwo = () => {
    return (
        <div className='navbar_two'>
            <div className='container'>
                <nav className="navbar navbar-expand-lg navbar_two">
                    <div className="container-fluid">
                        <button className="navbar-toggler px-0" type="button" data-mdb-toggle="collapse"
                            data-mdb-target="#navbarExampleOnHover" aria-controls="navbarExampleOnHover" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i className="fas fa-bars"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarExampleOnHover">
                            <ul className="d-flex justify-content-between me-auto navbar-nav ps-lg-0 w-100" >

                                <li className="nav-item dropdown dropdown-hover position-static">
                                    <Link className="nav-link dropdown-toggle mega_a" id="navbarDropdown" role="button"
                                        data-mdb-toggle="dropdown" aria-expanded="false">
                                        Data Privacy Services
                                    </Link>
                                    <div className="dropdown-menu dropdown_mega w-100 mt-0" aria-labelledby="navbarDropdown" >

                                        <div className="container">
                                            <div className="row my-4">
                                                {/* <h5 className='mega_heading mb-4 ps-4'>Services</h5> */}
                                               
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/services' className="list-group-item list_mega_item list-group-item-action" >INTIAL DATA-PRIVACY PROGRAM IMPLEMENTATION (IDPI)</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/services-officer' className="list-group-item list_mega_item list-group-item-action" >THE OUTSOURCED DATA PROTECTION OFFICER SERVICE (ODPO)</Link>

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/services-advisory' className="list-group-item list_mega_item list-group-item-action" >THE GENERAL SUPPORT, CONSULTING AND ADVISORY SERVICE (GSCA)</Link>

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/services-process' className="list-group-item list_mega_item list-group-item-action" >DATA PRIVACY PROCESS RE-ENGINEERING & ALIGNMENT (DPRA)</Link>

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div class="list-group list-group-flush">
                                                        <Link to='/services-security' className="list-group-item list_mega_item list-group-item-action" >THE CYBER SECURITY & TECHNICAL PRIVACY SERVICES (CSTP)</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/services-organisational' className="list-group-item list_mega_item list-group-item-action" >ORGANIZATIONAL & EXECUTIVE PRIVACY TRAINING & DEVELOPMENT (OPTD)</Link>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <Link to='/Corporate-Compliance' className="nav-link  mega_a">
                                        Corporate Compliance & Business Consultation
                                    </Link>
                                    {/* <div className="dropdown-menu dropdown_mega w-100 mt-0" aria-labelledby="navbarDropdown" >

                                        <div className="container">
                                            <div className="row my-4">
                                                <h5 className='mega_heading mb-4 ps-4'>Data Protection</h5>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                         <Link to='/' className="list-group-item list_mega_item list-group-item-action" >Finance</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                         <Link to='/' className="list-group-item list_mega_item list-group-item-action" >Healthcare</Link>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                         <Link to='/' className="list-group-item list_mega_item list-group-item-action" >Education</Link>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div> */}
                                </li>
                                <li className="nav-item ">
                                <Link to='/ifrs-services' className="nav-link  mega_a">
                                Actuarial & IFRS9 Services
                                    </Link>
                                </li>
                                {/* <li className="nav-item dropdown dropdown-hover position-static">
                                    <Link className="nav-link dropdown-toggle mega_a" id="navbarDropdown" role="button"
                                        data-mdb-toggle="dropdown" aria-expanded="false">
                                        Actuarial & IFRS9 Services
                                    </Link>
                                    <div className="dropdown-menu dropdown_mega w-100 mt-0" aria-labelledby="navbarDropdown" >

                                        <div className="container">
                                            <div className="row my-4">
                                                <h5 className='mega_heading mb-4 ps-4'> Actuarial & IFRS Services</h5>
                                                <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <h6 to='/' className="px-3 nav_head" >Group and Health insurance</h6>
                                                        <ul className='p-0 ul_back'>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>Group Life & AD&D</span></Link></li>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>Personal Accident (PA) insurance</span></Link></li>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>Individual Health (IH) insurance</span></Link></li>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>Group Health (GH) insurance</span></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <h6 to='/' className="px-3 nav_head" >IFRS 9</h6>
                                                        <ul className='p-0 ul_back'>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>IFRS 9 Loans</span></Link></li>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>IRS 9 Investments</span></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-5 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <h6 to='/' className="px-3 nav_head" >Credit Risk Management (CRM)</h6>
                                                        <ul className='p-0 ul_back'>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><Link className='align-items-center d-flex gap-1 list_mega_item'><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>Credit scorecard development and implementation</span></Link></li>
                                                            <li className='li_navbar list-group-item border-0 list-group-item-action'><div className='align-items-center d-flex gap-1 '><MdOutlineKeyboardDoubleArrowRight  className='fs-5' /><span>Risk based pricing (RBP) development and implementation</span></div>
                                                                <ul>
                                                                    <li className=''><Link className='list_mega_item'>Allocate Expenses % of loan balance</Link></li>
                                                                    <li className=''><Link className='list_mega_item'>Allocate Unit Cost by expenses and by risk class for each loan type</Link></li>
                                                                    <li className=''><Link className='list_mega_item'>Derive interest rate by risk class for each loan type</Link></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                <li className="nav-item dropdown dropdown-hover position-static">
                                    <Link className="nav-link dropdown-toggle mega_a" id="navbarDropdown" role="button"
                                        data-mdb-toggle="dropdown" aria-expanded="false">
                                        Industries
                                    </Link>
                                    <div className="dropdown-menu dropdown_mega w-100 mt-0" aria-labelledby="navbarDropdown" >

                                        <div className="container">
                                            <div className="row my-4">
                                                <h5 className='mega_heading mb-4 ps-4'>Industries</h5>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry' className="list-group-item list_mega_item list-group-item-action" >Education Industry </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry-medical' className="list-group-item list_mega_item list-group-item-action" >Medical Industry </Link>

                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-lg-3">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry-financial' className="list-group-item list_mega_item list-group-item-action" >Financial Industry </Link>

                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry-charities' className="list-group-item list_mega_item list-group-item-action" >Charities/Churches (Non-profit Organizations) </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry-hotel' className="list-group-item list_mega_item list-group-item-action" >Hotel and Tourism Industry </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry-Public' className="list-group-item list_mega_item list-group-item-action" >Public Sector Entities </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                                                    <div className="list-group list-group-flush">
                                                        <Link to='/industry-private' className="list-group-item list_mega_item list-group-item-action" >Private Sector Organisations</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default NavbarTwo
