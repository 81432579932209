import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img from '../../assets/add-image.png'

const Add = () => {
    
    const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
       <div className='container'>
        <div class="card box-shadow-add my-6">
          <div className='d-flex align-items-end row'>
            <div className='col-md-6 order-2 order-md-1'>
              <div className='card-body'>
                <h4 class="card-title mb-1 d-flex gap-2 flex-wrap">Streamline Your Business Operations with Ease!</h4>
                <p className="pb-0">Tired of the endless queues and waiting at the Companies Office of Jamaica? We've got your back!</p>
                <Link  to='/Corporate-Compliance' className="btn btn-sm btn-light px-4 waves-effect waves-light">View</Link>
              </div>

            </div>
          
            <div className='col-md-6 text-center text-md-end order-1 order-md-2'>
              <div className='card-body pb-0 px-0 px-md-4 ps-0'>
                <img src={img} height="180px" />
              </div>
            </div>

          </div>
        </div>
       
      </div>
    </div>
  )
}

export default Add
