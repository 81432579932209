import React from 'react'
import img from '../../assets/about-img.webp'
import team1 from '../../assets/uci-owner.png'
import team2 from '../../assets/our_team1.png'
import team3 from '../../assets/our_team2.png'
import team4 from '../../assets/our_team3.png'
import team5 from '../../assets/our_team4.png'
import ReadMore from '../ourteam/ReadMore'
import KnowOurTeam from '../ourteam/KnowOurTeam'

const AboutUs = () => {
  return (
    <div>
      <div className="background-aboutus">
        <div className="layer-aboutus text-center">
          <div className="container" >
            <div className="row">
              <div className="col-12">
              <h1 className='fs5-3 accent' >About Us</h1>
              <p className="text-white"> We are on a mission to transform data privacy and compliance in the region. </p>
            <p className="text-white text_justify" >Welcome to United Consulting International Limited. We are on a mission to transform data privacy and compliance. With a commitment to excellence, we seamlessly blend cutting-edge technology, strategic counsel, and innovative solutions to safeguard businesses. Beyond compliance, we architect frameworks for ethical data governance, turning challenges into growth opportunities. Think of us as trusted partners, fortifying your future, ensuring that trust becomes the currency of your success. Join us in this new era where UCI transforms data privacy into a cornerstone of triumph for your organisation.</p>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div className='container my-6' >
        <div id="content-aboutus" className='mb-5'>
          <div class="small-aboutus" >
            <div className='aboutus-our-mission flex-start' >
              <p className='m-0 line_height_about' >Our</p>
              <p className='m-0 line_height_about' >Mission</p>
            </div>
          </div>
          <div class="double" >
            <p className="text-dark text_justify" >At United Consulting International Limited, our mission is to empower organizations with robust and forward-thinking data privacy solutions. We are committed to ensuring the protection, integrity, and ethical use of personal information, enabling our clients to navigate the evolving landscape of data security with confidence. Through expert consultation and innovative strategies, we strive to build a digital world where privacy is not just a standard but a cornerstone of trust and accountability.</p>
          </div>
        </div>
        <div id="content-aboutus-reverse"  >
          <div class="double" >
            <p className="text-dark text_justify" >To be the forefront leader in data privacy consulting, United Consulting International Limited envisions a future where every organization, irrespective of size or industry, is equipped to champion privacy as a fundamental right. We aspire to set new benchmarks in privacy excellence, cultivating a global culture where businesses seamlessly integrate responsible data privacy practices, engendering trust, and fostering enduring relationships with their stakeholders. Our vision is to catalyze a paradigm shift where privacy becomes synonymous with innovation, and our clients become exemplars of ethical data stewardship.</p>

          </div>
          <div class="small-aboutus" >

            <div className='aboutus-our-mission flex-end' >
              <p className='m-0 line_height_about' >Our</p>
              <p className='m-0 line_height_about' >Vision</p>
            </div>
          </div>
        </div>
      </div>
     <KnowOurTeam />
    </div>
  )
}

export default AboutUs
