import React from 'react'

const PrincipleModal = () => {
    return (
        <div>
            <div className="modal fade z_index_popup" id="modalFairness" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" > Fairness and Lawfulness</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                            Personal data processing must be conducted with transparency, integrity, and compliance with the law. Deceptive or misleading means to obtain data are strictly prohibited. Processing requires explicit and informed consent from the data subject, ensuring it is freely given, specific, and unequivocal. Consent obtained under conditions that compromise the individual's freedom, such as tying it to the provision of goods or services, is not deemed 'freely given.'
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalPurpose" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" > Purpose Limitation</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Data collection must have a clear, lawful purpose, preventing any use incompatible with these specified purposes. Companies are obligated to outline the purpose of data collection, seeking consent where necessary. For instance, collecting customer data for service provision does not permit disclosure or sale to third parties for direct marketing without explicit consent.
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalMinimization" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" > Data Minimization</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Collected personal data must be adequate, relevant, and limited to the intended purpose. Excessive data processing, beyond what is reasonably necessary, is avoided to prevent an invasion of privacy.
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalAccuracy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" > Accuracy</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Personal data must be accurate and kept up-to-date. While inaccuracies provided by the data subject or a third party are not a breach, companies must take reasonable steps to verify and maintain accuracy.
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalStorage" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" > Storage Limitation</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Personal data must not be retained longer than necessary and should be disposed of according to regulations. Companies are required to inform data subjects about the expected retention period, clearly outlined in privacy notices.
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalRights" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" >  Rights of the Data Subject</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Data subjects have rights, including access to their data and the ability to prevent processing under specific circumstances, ensuring their data is processed in compliance with these rights.
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalImplementation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" >  Implementation of Technical and Organizational Measures</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Appropriate technical and organizational measures, such as security audits, data protection policies, and employee training, are implemented to prevent unauthorized processing, loss, destruction, or damage to personal data. Measures include pseudonymization, encryption, access restrictions, and maintaining up-to-date data-processing and antivirus software.
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade z_index_popup" id="modalCross" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 accent" >  Cross-Border Transfers</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mb-3">
                        Transferring personal data outside Jamaica requires ensuring an adequate level of protection for data subjects' rights and freedoms. The Commissioner considers factors like the nature of data, destination state or territory, local laws, international obligations, and security measures. Exceptions exist, including data subject consent, transfers for substantial public interest, or contract performance.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrincipleModal
