import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (
        <div>
            <div className='container py-5'>
                <h1 className='font mb-4'>Privacy Notice for United Consulting International Limited </h1>
                <p className=''>Last Updated: January 25, 2025</p>
                <p className='color_gray_privacy'>United Consulting International Limited ("we," "us," or "our") respects your privacy and is
                    committed to protecting your personal information in accordance with the Data Protection
                    Act, 2020, of Jamaica.</p>
                <div className='mt-4'>
                    <div>
                        <h5 className='f600'>1. Data Controller</h5>
                        <p className="color_gray_privacy">United Consulting International Limited is the data controller responsible for the processing
                            of your personal information.</p>
                    </div>

                    <div>
                        <h5 className='f600'>2. Information We Collect</h5>
                        <p className="color_gray_privacy">We collect and process various types of personal information, including but not limited to:</p>
                        <ul>
                            <li className='color_gray_privacy'>Names, contact details, and professional information.</li>
                            <li className='color_gray_privacy'>Information collected through cookies or similar technologies when you visit our
                                website.</li>
                        </ul>
                    </div>
                    <div>
                        <h5 className='f600'>3. Purpose of Processing</h5>
                        <p className="color_gray_privacy">We process your personal information for the following purposes:</p>
                        <ul>
                            <li className='color_gray_privacy'>Providing and maintaining our consulting services.</li>
                            <li className='color_gray_privacy'>Responding to your inquiries and communication.</li>
                            <li className='color_gray_privacy'>Improving our website and services.</li>
                            <li className='color_gray_privacy'>Marketing and promotional activities with your consent.</li>
                        </ul>
                    </div>
                    <div>
                        <h5 className='f600'>4. Legal Basis for Processing</h5>
                        <p className="color_gray_privacy">We process personal information based on the legal grounds outlined in the Data Protection
                            Act, 2020, including your consent, contractual necessity, and legitimate interests pursued by
                            us.</p>

                    </div>
                    <div>
                        <h5 className='f600'>5. Data Sharing</h5>
                        <p className="color_gray_privacy">We generally do not share the data collected via this website with Third Parties. If needed,
                            data sharing will be part of legitimate business interests and for the further provision of our
                            products and services, with your consent.</p>

                    </div>
                    <div>
                        <h5 className='f600'>6. Data Security</h5>
                        <p className="color_gray_privacy">We implement appropriate technical and organizational measures to protect your personal
                            information from unauthorized access, disclosure, alteration, and destruction.</p>

                    </div>
                    <div>
                        <h5 className='f600'>7. Your Rights</h5>
                        <p className="color_gray_privacy">As an individual, you possess the following rights regarding your personal data under the
                            Data Protection Act, 2020:</p>
                        <ul>
                            <li className='color_gray_privacy'>Right of Access: You have the right to access your personal data and request copies
                                of it, along with information about how we process it.</li>
                            <li className='color_gray_privacy'>Rectification and Addition: If your personal data is inaccurate or incomplete, you can
                                request corrections or additions.</li>
                            <li className='color_gray_privacy'>
                                Withdrawal of Consent: If we are processing your data based on your consent, you
                                have the right to withdraw that consent at any time.
                            </li>
                            <li className='color_gray_privacy'>
                                Objection to Processing: In cases where we use your data due to our legitimate
                                interests, you can object to such processing.
                            </li>
                            <li className='color_gray_privacy'>
                                Direct Marketing Objection: If we use your personal data for direct marketing or direct
                                marketing profiling, you can object to this usage.
                            </li>
                            <li className='color_gray_privacy'>
                                Data Processing Restriction: Under certain circumstances, you can request a
                                restriction on the processing of your data.
                            </li>
                            <li className='color_gray_privacy'>
                                Protection from Automated Decisions: You have the right not to be subject to
                                decisions based solely on automated processing, including profiling, that significantly
                                affect you or produce legal effects.
                            </li >
                        </ul>

                    </div>
                    <div>
                        <h5 className='f600'>8. Data Retention</h5>
                        <p className="color_gray_privacy">We keep your personal information for a duration deemed reasonably necessary for our
                            ongoing relationship, in compliance with legal obligations, or until such time that you raise
                            objections to the processing of your data or withdraw your consent.</p>

                    </div>
                    <div>
                        <h5 className='f600'>9. Cookie Usage</h5>
                        <p className="color_gray_privacy">Our website utilizes cookies to enhance user experiences during their visit. In compliance
                            with regulations, we have implemented a cookie control system, granting users the ability to
                            explicitly allow or deny the use or storage of cookies on their computer/device.</p>

                    </div>
                    <div>
                        <h5 className='f600'>10. What Are Cookies?</h5>
                        <p className="color_gray_privacy">Cookies are small files stored on the user's computer's hard drive, tracking, saving, and
                            storing information about their interactions and usage of the website. Through its server, the
                            website can provide users with a personalized experience.</p>
                        <p className="color_gray_privacy">
                            Users are advised that if they prefer to deny the use and storage of cookies, they should
                            take necessary steps within their web browser's security settings to block all cookies from
                            this website or use the available cookie control system on their first visit.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>11. Visitor Tracking</h5>
                        <p className="color_gray_privacy">Our website employs tracking software to monitor visitor activity, providing insights into
                            usage patterns. The software saves a cookie to the user&#39;s computer&#39;s hard drive to track and
                            monitor engagement and usage of the website, but it does not store, save, or collect
                            personal information.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>12. Adverts and Sponsored Links</h5>
                        <p className="color_gray_privacy">Sponsored links and adverts may appear on our website, typically served through
                            advertising partners with detailed privacy policies related to the served adverts. Clicking on
                            such adverts may redirect users to the advertiser&#39;s website through a referral program,
                            which may use cookies to track referrals.
                        </p>
                        <p className="color_gray_privacy">
                            Users should be aware that clicking on sponsored external links is done at their own risk,
                            and the website cannot be held liable for any damages or implications caused by visiting
                            such external links.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>13. Downloads & Media Files</h5>
                        <p className="color_gray_privacy">Any downloadable documents, files, or media available on this website are provided at
                            users' own risk. While precautions are taken to ensure genuine downloads, users are
                            advised to verify their authenticity using third-party antivirus software.
                        </p>
                        <p className="color_gray_privacy">
                            The website accepts no responsibility for third-party downloads and downloads provided by
                            external third-party websites. Users are advised to verify their authenticity using third-party
                            antivirus software.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>14. Contact & Communication</h5>
                        <p className="color_gray_privacy">Users reaching out to us through this website do so at their discretion, providing any
                            requested personal details at their own risk. Personal information is kept private and stored
                            securely until it is no longer required or has no use.
                        </p>
                        <p className="color_gray_privacy">
                            Where explicitly stated and with given permission, personal details may be used to send
                            products/services information through a mailing list system.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>15. Email Mailing List & Marketing Messages</h5>
                        <p className="color_gray_privacy">We may operate an email mailing list programme to inform subscribers about products,
                            services, and/or news. Users can subscribe through an online automated process where
                            they provide explicit permission.
                        </p>
                        <p className="color_gray_privacy">
                            Subscriber personal details are collected, processed, managed, and stored in accordance
                            with regulations. Subscribers can unsubscribe at any time through an automated online
                            service or other available means.
                        </p>
                        <p className="color_gray_privacy">
                            Email marketing messages may contain tracking beacons, tracked clickable links, or similar
                            server technologies to record subscriber data relating to engagement, demographics, and
                            already stored subscriber data.
                        </p>
                        <p className="color_gray_privacy">Note: We do not use an EMS (email marketing service) at this time.</p>
                    </div>
                    <div>
                        <h5 className='f600'>16. External Website Links & Third Parties</h5>
                        <p className="color_gray_privacy">While efforts are made to include quality, safe, and relevant external links, users are advised
                            to exercise caution before clicking any external web links. Clicking on external links is done
                            at the user's own risk, and the website cannot be held liable for any damages or implications
                            caused by visiting external links.
                        </p>
                        <p className="color_gray_privacy">
                            Shortened URL’s: Users should exercise caution before clicking on shortened URL links and
                            verify their authenticity before proceeding.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>17. Social Media Policy & Usage</h5>
                        <p className="color_gray_privacy">A Social Media Policy is adopted to ensure appropriate online conduct for the business and
                            staff. While official profiles on social media platforms may exist, users are advised to verify
                            their authenticity before engaging with or sharing information with such profiles.
                        </p>
                        <p className="color_gray_privacy">
                            The website may feature social sharing buttons allowing users to share web content directly
                            from web pages to respective social media platforms. Users use social sharing buttons at
                            their discretion, understanding that doing so may publish content to their social media
                            profiles.
                        </p>
                    </div>
                    <div>
                        <h5 className='f600'>18. Policy Update</h5>
                        <p className="color_gray_privacy">Our commitment to safeguarding your privacy includes periodic reviews and updates to our
                            practices. We may revise this Privacy Notice to align with changes in our operations,
                            industry standards, or applicable laws. Any modifications will be promptly communicated by
                            posting the updates on our website. It is advisable to check our Privacy Notice regularly for
                            the latest information on how we handle your personal data.
                        </p>

                    </div>
                    <div>
                        <h5 className='f600'>19. Contact Us:</h5>
                        <p className="color_gray_privacy">Should you have any inquiries, concerns, or wish to exercise your rights under the Data
                            Protection Act, 2020, please do not hesitate to reach out to us at <Link to="mailto:corporatesolutions@uciconsult.com">corporatesolutions@uciconsult.com</Link> alternatively, you can call us at 876-239-1727. We are
                            here to address any questions you may have regarding the processing of your personal
                            information and ensure your rights are respected.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
