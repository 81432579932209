import Actuarial from "./components/Actuarial/Actuarial";
import Corporate from "./components/CorporateCompliance/Corporate";
import Add from "./components/HomePage/Add";
import Home from "./components/HomePage/Home";
import IndustryCharities from "./components/Industry/IndustryCharities";
import IndustryFinancial from "./components/Industry/IndustryFinancial";
import IndustryHotel from "./components/Industry/IndustryHotel";
import IndustryMedical from "./components/Industry/IndustryMedical";
import IndustryPages from "./components/Industry/IndustryPages";
import IndustryPrivate from "./components/Industry/IndustryPrivate";
import IndustryPublic from "./components/Industry/IndustryPublic";
import Index from "./components/MakeAppointment/Index";
import MakeAppointment from "./components/MakeAppointment/MakeAppointment";
import TermsCondition from "./components/Terms/TermsCondition";
import AboutUs from "./components/aboutus/AboutUs";
import Overview from "./components/aboutus/Overview";
import BlogDetail from "./components/blog/BlogDetail";
import BlogList from "./components/blog/BlogList";
import ContactUs from "./components/contact/ContactUs";
import Gallery from "./components/gallery/Gallery";
import KnowOurTeam from "./components/ourteam/KnowOurTeam";
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import AdvisoryServices from "./components/services/AdvisoryServices";
import OfficerServices from "./components/services/OfficerServices";
import OrganisationalServices from "./components/services/OrganisationalServices";
import ProcessServices from "./components/services/ProcessServices";
import SecurityServices from "./components/services/SecurityServices";
import ServicePage from "./components/services/ServicePage";

export const route = [
    {
        id: 1,
        name: "Home",
        path: "/",
        component: <Home />,
    },
    {
        id: 2,
        name: "About Us",
        path: "/aboutUs",
        component: <AboutUs />,
    },
    {
        id: 3,
        name: "Contact Us",
        path: "/contactUs",
        component: <ContactUs />,
    },
    {
        id: 4,
        name: "Know Our Team",
        path: "/know-our-team",
        component: <KnowOurTeam />,
    },
    {
        id: 5,
        name: "Overview",
        path: "/overview",
        component: <Overview />,
    },
    {
        id: 6,
        name: "Service page",
        path: "/services",
        component: <ServicePage />,
    },
    {
        id: 7,
        name: "Blog list",
        path: "/allblog",
        component: <BlogList />,
    },
    {
        id: 8,
        name: "Blog detail",
        path: "/allblog/blogdetail",
        component: <BlogDetail />,
    },
    {
        id: 9,
        name: "Gallery",
        path: "/gallery",
        component: <Gallery />,
    },
    {
        id: 10,
        name: "Corporate Compliance",
        path: "/Corporate-Compliance",
        component: <Corporate />,
    },
    {
        id: 11,
        name: "Terms of Use",
        path: "/terms-use",
        component: <TermsCondition />,
    },
    {
        id: 12,
        name: "Industry",
        path: "/industry",
        component: <IndustryPages />,
    },
   
    {
        id: 13,
        name: "Make Appointment",
        path: "/appointment",
        component: <Index />,
    },
    {
        id: 14,
        name: "Make Appointment",
        path: "/appointment",
        component: <MakeAppointment />,
    },
    {
        id: 15,
        name: "Industry-Medical",
        path: "/industry-medical",
        component: <IndustryMedical />,
    },
    {
        id: 16,
        name: "Industry-Financial",
        path: "/industry-financial",
        component: <IndustryFinancial />,
    },
    {
        id: 17,
        name: "Industry-Charities",
        path: "/industry-charities",
        component: <IndustryCharities />,
    },
    {
        id: 18,
        name: "Industry-Hotel ",
        path: "/industry-hotel",
        component: <IndustryHotel />,
    },
    {
        id: 19,
        name: "Industry-Public ",
        path: "/industry-Public",
        component: <IndustryPublic />,
    },
    {
        id: 20,
        name: "Privacy-Policy ",
        path: "/privacy",
        component: <PrivacyPolicy />,
    },
    {
        id: 21,
        name: " Actuarial IFRS9 Services ",
        path: "/ifrs-services",
        component: <Actuarial />,
    },
    {
        id: 22,
        name: "Industry-Private ",
        path: "/industry-private",
        component: <IndustryPrivate />,
    },
    {
        id: 23,
        name: "Service page1",
        path: "/services-officer",
        component: <OfficerServices />,
    },
    {
        id: 24,
        name: "Service page2",
        path: "/services-advisory",
        component: <AdvisoryServices />,
    },
    {
        id: 25,
        name: "Service page3",
        path: "/services-process",
        component: <ProcessServices />,
    },
    {
        id: 26,
        name: "Service page4",
        path: "/services-security",
        component: <SecurityServices />,
    },
    {
        id: 27,
        name: "Service page5",
        path: "/services-organisational",
        component: <OrganisationalServices />,
    },
    {
        id: 28,
        name: "Add",
        path: "/add",
        component: <Add />,
    },
   
]