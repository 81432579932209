import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import video from '../../assets/video-bg.mp4'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const ImageSection = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  const [show, setShow] = useState(false);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  return (
    <div className="">
      <div className='mainbg-outer' >

        <video className='background' id="bannerVideo" autoPlay muted loop playsInline poster="poster_image.jpg">
          <source src={video} type="video/mp4" />
        </video>
        <div className="slider_head text-center px-2" >
          <h1 className='fs5-3 text-white'>Your Data, Our Priority</h1>
          <p className="fs10 text-white mb-3">Your Data Protection Solutions Partner</p>
          {/* <p className='fs10 text-white'>Globaly</p> */}
          <button class="custom-btn btn-12"><span ><Link to='/contactUs' className='text-white'  >Click!</Link></span><span>Contact Us</span></button>
        </div>
        <Link onClick={() => { handleClose(); scrollToTop() }} to='/contactUs' class="request_call text-white btn py-1 rounded-1">Request a callback</Link>
      </div>


    </div>

  )
}

export default ImageSection
