import React from 'react'
import img from '../../assets/advertisement-pic.jpg'

const Corporate = () => {
  return (
    <div>
      <div className='bg_Corporate py-5'>
        <div className='mx-auto w-75 text-center'>
          <h2 class="accent font f35 mb-3">Corporate Compliance & Business Consultation Services</h2>
          <p className='text_justify'>United Consulting International Limited offers a comprehensive suite of services aimed at ensuring corporate compliance and providing valuable business consultation. Our commitment is rooted in a vision to protect, educate, and guide businesses towards enduring excellence. Our mission is to deliver unparalleled business consulting services, empowering organizations to achieve strategic excellence, sustainable growth, and lasting success.</p>
        </div>
      </div>
      <div className='container py-5'>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>1</b></span> <span>Corporate Compliance Assessment:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>We conduct thorough assessments to ensure that businesses adhere to regulatory requirements and compliance standards.</li>
            <li className='mb-2 li_clr'>Our experts analyze existing processes, identify gaps, and recommend corrective measures to mitigate compliance risks.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>2</b></span> <span> Business Consultation Services:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>Strategic Planning: Collaboratively develop comprehensive strategic plans aligned with your organizational goals.</li>
            <li className='mb-2 li_clr'>Process Optimization: Streamline and optimize business processes to enhance efficiency and reduce operational costs.</li>
            <li className='mb-2 li_clr'>Risk Management: Identify and manage risks proactively to safeguard your business and ensure long-term stability.</li>
            <li className='mb-2 li_clr'>Market Analysis: Gain valuable insights into market trends, competition, and opportunities to inform informed decision-making.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>3</b></span> <span>Governance and Ethics:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>Establish robust governance structures and ethical frameworks to foster a culture of integrity and accountability.</li>
            <li className='mb-2 li_clr'>Provide guidance on ethical decision-making, corporate responsibility, and stakeholder relations.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>4</b></span> <span>Compliance Training:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>Develop customized training programs to educate employees on compliance requirements and ethical standards.</li>
            <li className='mb-2 li_clr'>Foster a culture of awareness and responsibility, ensuring all staff members understand and adhere to compliance protocols.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>5</b></span> <span>Regulatory Compliance Support:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>Stay informed and compliant with ever-changing regulations through continuous monitoring and updates.</li>
            <li className='mb-2 li_clr'>Provide ongoing support to address regulatory changes and implement necessary adjustments.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>6</b></span> <span> Business Transformation Advisory:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>Guide businesses through transformative change by identifying growth opportunities and strategic initiatives.</li>
            <li className='mb-2 li_clr'>Assist in implementing innovative solutions to drive sustainable growth and enhance overall organizational performance.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>7</b></span> <span>Corporate Sustainability Solutions:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>Design and implement sustainability strategies to promote responsible business practices.</li>
            <li className='mb-2 li_clr'>Assist in achieving environmental, social, and governance (ESG) goals for long-term business resilience.</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='accent d-flex gap-2 mb-3'>  <span><b>8</b></span> <span>Board Evaluation and Training:</span></h5>
          <ul>
            <li className='mb-2 li_clr'>At UCI, our Board Evaluation service is designed to enhance the effectiveness and governance of your organization's Board. Through a structured process, we assess the Board's performance, identify areas for improvement, and provide actionable recommendations. Our approach not only strengthens governance practices but also ensures alignment with strategic objectives, fostering a culture of continuous improvement and excellence in leadership. This service is essential for organizations seeking to optimize their Board's contribution to long-term success.
            </li>
          </ul>
        </div>
        <div className='mb-5'>
          <p>At United Consulting International Limited, we are not just consultants; we are your partners in success, dedicated to steering your business towards enduring excellence in a dynamic and competitive landscape.</p>
        </div>
        <div className='row align-items-center' id='addUci'>
          <h4 className='font  mb-2'>Streamline Your Business Operations with Ease! </h4>
          <h4 className='font  '>Tired of the endless queues and waiting at the Companies Office of Jamaica? We've got your back! </h4>
          <div className='col-lg-5 mt-4'>
            <div className='text-center'>
              <img src={img} className='img-fluid' />
            </div>
          </div>
          <div className='col-lg-7 mt-4'>
            <div className='card card_shodow1 '>
              <div className='card-body'>
                <div className='mb-3'>
                  <h6 className='accent'>  Introducing Hassle-Free Document Filings:</h6>
                  <ul>
                    <li className=' li_clr f14'>	Say goodbye to long lines and tedious waits.</li>
                    <li className='li_clr f14'>	Reclaim your valuable time to focus on what matters most – your business!</li>
                  </ul>
                </div>
                <div className='mb-3 '>
                  <h6 className='accent'>  Our Promise to You:</h6>
                  <ul>
                    <li className=' li_clr f14'>		Efficient, swift, and accurate document filings.</li>
                    <li className='li_clr f14'>	A dedicated team to handle all your transactions seamlessly.</li>
                  </ul>
                </div>
                <div className='mb-3 '>
                  <h6 className='accent'> Maximize Your Productivity:</h6>
                  <ul>
                    <li className='li_clr f14 '>		While we take care of the paperwork, you can focus on growing your business.</li>
                    <li className='li_clr f14'>		Handle other crucial tasks without the stress of administrative hassles.</li>
                  </ul>
                </div>
                <div className='mb-3 '>
                  <h6 className='accent'> Time is Money – Let's Save Both:</h6>
                  <ul>
                    <li className='li_clr f14 '>	No more wasted hours waiting in line.</li>
                    <li className='li_clr f14'>		Expedited services to ensure your documents are filed promptly.</li>
                  </ul>
                </div>
                <div className='mb-3 '>
                  <h6 className='accent'>Why Choose Us?</h6>
                  <ul>
                    <li className='li_clr f14 '>		Proven expertise in navigating the Companies Office processes.</li>
                    <li className='li_clr f14'>		Trusted partners committed to your business success.</li>
                  </ul>
                </div>
                <p className='f14 mb-0'>Ready to experience a new level of convenience? Let us be your business ally, making your document filings a breeze. Contact us today!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Corporate
