import React, { useState, useRef } from 'react';
import video1 from '../../assets/video_plyr.mp4';
import { FaRegCirclePlay } from "react-icons/fa6";

const VideoSection = () => {
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayToggle = () => {
    const video = videoRef.current;

    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }

    setPlaying(!isPlaying);
  };

  const handleVideoPause = () => {
    setPlaying(false);
  };

  return (
    <div>
      <div className='py-4 my-2 bg_video'>
        <div className='container'>
          <div className='row flex-md-column-reverse flex-lg-row'>
            <div className='col-md-12 col-lg-6 mb-4 mb-md-0'>
              <h2 className='my-3 font  fw-medium'>The Data Protection Act, 2020</h2>
              <p className='pe-lg-4 text_justify'>
              Explore the core principles of Jamaica's Data Protection Act, 2020 with United Consulting International Limited. Focusing on the eight crucial standards outlined in the Act, this video will enlighten both companies and individuals about the importance of understanding and managing how their data is processed. We emphasize the significance of awareness and compliance, inviting organizations and private citizens alike to engage with us. </p>
             <p className='pe-lg-4 text_justify'>Let United Consulting International Limited guide you toward full compliance with the Jamaica Data Protection Act, ensuring that your data handling practices respect privacy, security, and legal standards. Discover how we can assist in safeguarding your information and adhering to these essential regulations. </p>
            
            </div>
            <div className='col-md-12 col-lg-6 my-auto'>
              <div className='video-container'>
                <video
                  ref={videoRef}
                  className='video-responsive'
                  id='bannerVideo'
                  controls
                  playsInline
                  onPlay={() => setPlaying(true)}
                  onPause={handleVideoPause}
                  
                >
                  <source src={video1} type='video/mp4' />
                </video>
                {!isPlaying && (
                  <span className='play-button ' onClick={handlePlayToggle}>
                    <FaRegCirclePlay />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
