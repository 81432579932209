import React from 'react'
import img from '../../assets/contact.webp'
import img2 from '../../assets/about-img.webp'
import img3 from '../../assets/blog4.avif'
import img4 from '../../assets/blog2.jpg'
import img5 from '../../assets/helpimage.jpg'
import img6 from '../../assets/Cyber-Security.jpg'

const Gallery = () => {
    return (
        <div>
            <div className='gallery_bg py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='text-center accent fs5-3 mb-5'>Gallery</h1>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='img_div'>
                                <img src={img} className='img-fluid gallery_img' />
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='img_div'>
                                <img src={img2} className='img-fluid gallery_img' />
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='img_div'>
                                <img src={img3} className='img-fluid gallery_img' />
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='img_div'>
                                <img src={img4} className='img-fluid gallery_img' />
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='img_div'>
                                <img src={img5} className='img-fluid gallery_img' />
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='img_div'>
                                <img src={img6} className='img-fluid gallery_img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gallery
