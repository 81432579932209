import React from 'react'

const TermsCondition = () => {
    return (
        <div>
            <div className='container py-5'>
                <h1 className='font mb-4'>Terms of Use </h1>
                <h5>Welcome to United Consulting International Limited's website</h5>
                <p className='clr_p'>If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms of use, which, together with our privacy policy, govern United Consulting International Limited's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>
                <p className='clr_p'>The term 'United Consulting International Limited,' 'us,' or 'we' refers to the owner of the website, whose registered office is 4-6 Argyle Road, Kingston 5. Our company registration number is xxxxx. The term 'you' refers to the user or viewer of our website.</p>
                <div className='mt-5'>
                    <h5>The use of this website is subject to the following terms of use:</h5>
                    <ol>
                        <li className='mb-3 clr_p'>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                        <li className='mb-3 clr_p'>This website uses cookies to monitor browsing preferences but does not store any personal information about you individually. (Google Analytics)</li>
                        <li className='mb-3 clr_p'>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                        <li className='mb-3 clr_p'>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</li>
                        <li className='mb-3 clr_p'>This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                        <li className='mb-3 clr_p'>All trademarks reproduced on this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
                        <li className='mb-3 clr_p'>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
                        <li className='mb-3 clr_p'>From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                        <li className='mb-3 clr_p'>Your use of this website and any dispute arising out of such use of the website is subject to the laws of Jamaica and is in accordance with the Data Protection Act, 2020, of Jamaica.</li>

                    </ol>
                    <p>Thank you for using United Consulting International Limited's website.</p>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition
