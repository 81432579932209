import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const OfficerServices = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div className="background-service-home">
                <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">The Outsourced Data Protection Officer services</h1>
            </div>
            <div className='container py-5'>
               <div className='row'>
               <div className='col-md-8 mb-4'>
                    <h2 class="accent font mb-3">The Outsourced Data Protection Officer services  </h2>
                    <p className='pe-lg-5 text_justify'>Outsourced Data Protection Officer (DPO) services offer a strategic solution for organizations seeking expertise in data privacy and compliance without the need for in-house specialization. These services provide access to seasoned privacy professionals who bring a wealth of knowledge on data protection laws, such as the GDPR, the Jamaica Data Protection Act, and industry best practices. An outsourced DPO is responsible for monitoring compliance, conducting data protection impact assessments, advising on data protection obligations, and serving as a point of contact between the organization and supervisory authorities. This arrangement not only ensures that an organization's data handling practices are up to par with legal requirements but also enhances data security, mitigates risks, and builds trust with customers and stakeholders by demonstrating a commitment to data privacy.</p>
                </div>
                <div className='col-md-4 mb-4'>
                    <div className='card card_shodow1 border-0'>
                        <h5 className='card-header bg_data_ul py-3 '>Data Privacy Services</h5>
                        <div className='card-body'>
                            <ul className='mb-0'>
                                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'>Initial Data Privacy Programme Implementation </Link></li>
                                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-advisory' className='clr_data_list'>The General Support, Consulting and Advisory Service </Link></li>
                                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-process' className='clr_data_list'>Data Privacy Process Re-engineering and Alignment </Link></li>
                                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-security' className='clr_data_list'>The Security and Technical Privacy Services </Link></li>
                                <li className='f14  marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-organisational' className='clr_data_list'>Organisational and Executive Privacy Training Development </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
               </div>
            </div>
        </div>
    )
}

export default OfficerServices
