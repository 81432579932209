import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const OrganisationalServices = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="background-service-home">
        <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">Organisational and Executive Privacy Training Development</h1>
      </div>
      <div className='container py-5'>

        <div className='row'>
          <div className='col-md-8 mb-4'>
            <h2 class="accent font mb-3">Organisational and Executive Privacy Training Development   </h2>
            <p className='pe-lg-5 text_justify'>Organizational and Executive Privacy Training Development focuses on creating tailored training programmes aimed at educating corporate leaders and employees on the importance of data privacy, the ethical handling of personal information, and compliance with relevant laws and regulations. This initiative seeks to embed a culture of privacy awareness across all levels of an organization, ensuring that every team member understands their role in protecting data and the potential consequences of privacy breaches.</p>
            <p className='pe-lg-5 text_justify'> Through interactive workshops, seminars, and online modules, participants are equipped with the knowledge and tools necessary to navigate the complex landscape of data protection, make informed decisions, and implement best practices in their daily operations. This proactive approach not only enhances an organization's privacy posture but also fosters a more informed, compliant, and secure working environment.</p>
          </div>
          <div className='col-md-4 mb-4'>
            <div className='card card_shodow1 border-0'>
              <h5 className='card-header bg_data_ul py-3 '>Data Privacy Services</h5>
              <div className='card-body'>
                <ul className='mb-0'>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'>Initial Data Privacy Programme Implementation </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-officer' className='clr_data_list'>The Outsourced Data Protection Officer services </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-advisory' className='clr_data_list'>The General Support, Consulting and Advisory Service </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-process' className='clr_data_list'>Data Privacy Process Re-engineering and Alignment </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-security' className='clr_data_list'>The Security and Technical Privacy Services </Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganisationalServices
