import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const AdvisoryServices = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
      setShow(false);
  };

  const scrollToTop = () => {
      window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="background-service-home">
        <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">The General Support, Consulting and Advisory Service</h1>
      </div>
      <div className='container py-5'>
        <div className='row'>
        <div className='col-md-8 mb-4'>
          <h2 class="accent font mb-3">The General Support, Consulting and Advisory Service  </h2>
          <p className='pe-lg-5 text_justify'>The General Support, Consulting, and Advisory Service is a comprehensive offering designed to assist organizations in navigating the complexities of their operational, strategic, and regulatory environments. Through expert guidance, these services offer actionable insights and tailored advice across a wide range of areas, including strategic planning, process improvement, compliance, risk management, and technology implementation. With a focus on delivering practical solutions and fostering organizational growth, the consulting and advisory teams work closely with clients to understand their unique challenges and opportunities. This collaborative approach ensures that the solutions provided are not only effective but also aligned with the organization's goals and values, empowering them to achieve sustainable success.</p>
        </div>
        <div className='col-md-4 mb-4'>
          <div className='card card_shodow1 border-0'>
            <h5 className='card-header bg_data_ul py-3 '>Data Privacy Services</h5>
            <div className='card-body'>
              <ul className='mb-0'>
                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'>Initial Data Privacy Programme Implementation </Link></li>
                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-officer' className='clr_data_list'>The Outsourced Data Protection Officer services </Link></li>
                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-process' className='clr_data_list'>Data Privacy Process Re-engineering and Alignment </Link></li>
                <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-security' className='clr_data_list'>The Security and Technical Privacy Services </Link></li>
                <li className='f14  marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-organisational' className='clr_data_list'>Organisational and Executive Privacy Training Development </Link></li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default AdvisoryServices
