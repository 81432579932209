import React from 'react'
import img1 from '../../assets/ind-5.png';

const IndustryPrivate = () => {
  return (
    <div className='min-height_tab'>
            <div className='industry_slider'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-12'>
                            <h1 className="fs5-3 accent mb-0 blog_slider_head text-center text-white">Private Sector Organisations</h1>

                        </div>
                    </div>
                </div>
                <svg id="opt_5" data-name="opt 5" xmlns="http://www.w3.org/2000/svg" width="100%" height="64" viewBox="0 0 1366 64" preserveAspectRatio="none" className='svg_slider'>
                    <defs>
                        <style>{`.cls-1{fill:currentColor;fill-rule:evenodd;}`}</style>
                    </defs>
                    <g id="Wave-7">
                        <path id="Rectangle" className="cls-1" d="M0,61.49C623,61.49,699,13.1117,1366,0V64H0Z"></path>
                    </g>
                </svg>

            </div>
            <div className='container py-5'>
               
                <div className='row'>
                    <div className='col-md-3 col-xxl-2 align-items-center col-md-3 d-flex justify-content-center mb-4 mb-md-0'>
                        <div className='bg-warning-ind'>
                            <img src={img1} className='img_ind' />
                        </div>
                    </div>
                    <div className='col-md-9 col-xxl-10'>
                    <div className='industry_content'>
                    <h2 class="accent font mb-3">Private Sector Organisations  </h2>
                    <p className='text_justify'> Private sector organisations in Jamaica, handling diverse sensitive data, are required to comply with the Jamaica Data Protection Act, 2020, among other regulations. United Consulting International recognizes the unique data management needs of the private sector and customizes its data privacy solutions accordingly. Our services include implementing robust data protection strategies, ensuring adherence to legal standards, and safeguarding the privacy of customer and corporate information. We focus on securing data integrity, supporting business operations with compliant data practices, and continuously updating our approaches to align with the latest data protection laws and industry-specific requirements.</p>
                </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default IndustryPrivate
