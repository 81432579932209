import React from 'react'
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'

const Actuarial = () => {
    return (
        <div>
            <div className="background_ifrac">
                <h1 className="text-center mb-0 fs5-3 accent blog_slider_head"> Actuarial & IFRS9 Services</h1>
            </div>
            <div className='container py-5'>
                <p className='text_justify'> Our Actuarial & IFRS9 Services are tailored to support organizations in navigating the complexities of financial reporting and risk management. Leveraging our deep actuarial expertise, we provide comprehensive solutions for IFRS9 compliance, including expected credit loss modeling, risk assessment, and valuation services. Our approach is designed to enhance financial accuracy and strategic decision-making, ensuring our clients meet regulatory requirements with confidence while optimizing their financial and operational performance.</p>
                <div className='row'>
                    <h4 className='py-4 mb-2 accent'> Actuarial & IFRS Services:</h4>
                    <div className="col-md-6 col-lg-4 mb-3 ">
                        <div className="card card_shodow1 h-100">
                            <h5 to='/' className="card-header card-header-clr " >Group and Health insurance</h5>
                            <div className="card-body">
                                <ul className='p-0 mb-0 '>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-6' /><span>Group Life & AD&D</span></Link></li>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-6' /><span>Personal Accident (PA) insurance</span></Link></li>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-6' /><span>Individual Health (IH) insurance</span></Link></li>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-6' /><span>Group Health (GH) insurance</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3 ">
                        <div className="card card_shodow1 h-100">
                            <h5 to='/' className="card-header card-header-clr " >IFRS 9</h5>
                            <div className="card-body">
                                <ul className='p-0 mb-0 '>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-6' /><span>IFRS 9 Loans</span></Link></li>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-6' /><span>IRS 9 Investments</span></Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-3 ">
                        <div className="card card_shodow1 h-100">
                            <h5 to='/' className="card-header card-header-clr " >Credit Risk Management (CRM)</h5>
                            <div className="card-body">
                                <ul className='p-0 mb-0 '>
                                    <li className='list_none'><Link className='clr_link_acturials'><MdOutlineKeyboardDoubleArrowRight className='fs-5' /><span>Credit scorecard development and implementation</span></Link></li>
                                    <li className='list_none'><div className='d_flex_li'><MdOutlineKeyboardDoubleArrowRight className='fs-5' /><span>Risk based pricing (RBP) development and implementation</span></div>
                                        <ul>
                                            <li className=''><Link className='clr_link_acturials fs14_ac'>Allocate Expenses % of loan balance</Link></li>
                                            <li className=''><Link className='clr_link_acturials fs14_ac'>Allocate Unit Cost by expenses and by risk class for each loan type</Link></li>
                                            <li className=''><Link className='clr_link_acturials fs14_ac'>Derive interest rate by risk class for each loan type</Link></li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Actuarial
