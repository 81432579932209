import React from 'react';
import team1 from '../../assets/uci-owner.png'
import team2 from '../../assets/our_team1.png'
import team3 from '../../assets/our_team2.png'
import team4 from '../../assets/our_team3.png'
import team5 from '../../assets/our_team4.png'
import ReadMore from './ReadMore'


const KnowOurTeam = () => {
  const [hide, setShow] = React.useState(true);
  return (
    <div>
      <div className="container my-4" >
        <h2 className="accent font" >Our Team</h2>
        <p>Our team collectively has over 30 years' experience in data privacy and protection, corporate compliance, law, international development, business consulting, and finance.We have therefore established the credibility of you entrusting your business in the hands of experienced and competent professionals.
        </p>
        <p>
          Our team has collectively serviced dozens of clients within the public sector, private sector, non-profit sector, financial services industry, manufacturing, education, hospitality and health-care sectors, locally and internationally.
        </p>
      </div>
      <div className="container my-6" >
        <div className="row my-1" >
          <div className="col-lg-3 col-md-4 our-team-responsive" >
            <img src={team1} className="img-ourTeam" />
            <div className="ring" >
            </div>
          </div>
          <div className="col-lg-9 col-md-8 mb-md-4 mb-lg-0" >
            <h3>Najuequa Barnes (BSc., LLB., MSc., CIPM)</h3>
            <h5 className="accent" >Chief Privacy Officer/Managing  Director (Chevening Scholar)</h5>
            {/* <ReadMore> */}
             <p className='mb-2 text_justify'> Najuequa Barnes is a corporate consultant, certified information privacy professional, development professional and adjunct lecturer. She has managed high level research and provides advisory consultation on varying corporate, data privacy, and international developmental issues for companies both locally and internationally. She has taken a hand-holding approach in assisting companies to assess and design tailored data privacy strategies for their organizations. Her professional experience spans the public and private sector, including academia at the tertiary level with over 14 years’ experience in corporate consulting, data privacy, business development, international development management and academia. She has designed and engaged in curriculum development, providing corporate training to C-suite executives, as well as staff at all organizational levels, including tailored data privacy training. </p>
             
            {/* </ReadMore> */}
            {!hide && <div> <p className='mb-2 text_justify'>She holds a Bachelor of Science from the University of the West Indies, Mona in International Relations, with a minor in Social Policy and Administration. She earned a law degree from the University of the West Indies, Cavehill Campus (Barbados); a Master of Science at the University of Manchester (UK) in International Development: Development Management; and is a Certified Information Privacy Manager (from the International Association of Privacy Professionals); certification in Project Management (Distinction); and certificate in Business Pitching Skills for Future Leaders (UK).</p>
              <p className='text_justify'> She is also a director of UNESCO Clubs, Jamaica; she received the Governor-General Achievement Award for Excellence in Academics and Community Development, and is an I Believe Initiative Ambassador. She is the founder of Global Angels Support, a non-profit organisation aimed at providing support for individuals who are going through the process of grieving.</p>
              <p className='mb-2 text_justify'>Najuequa conceptualized the Mindfulness Jamaica Initiative, with the support of the British High Commission, members of the Chevening Alumni Community, along with other partners and sponsors, which seeks to provide mental health awareness among adolescents throughout Jamaica.</p>
               </div>}
            <button className="mb-3 mt-1 text-white btn btn-warning d-block" onClick={() => setShow(!hide)}>
              {!hide ? "Read Less" : "Read More"}
            </button>

            
          </div>
        </div>

        <div className="row my-1" >
          <div className="col-lg-3 col-md-4 our-team-responsive" >
            <img src={team2} className="img-ourTeam" />
            <div className="ring" >
            </div>
          </div>
          <div className="col-lg-9 col-md-8 mb-md-4 mb-lg-0" >
            <h3>Cleveland Barnes (BSc., ASA, HIA)</h3>
            <h5 className="accent" >Advisory & Risk Assessment Director </h5>
            <ReadMore className="text_justify">
              Cleveland Barnes is a Group Health and IFRS 9 Actuarial Consultant, with over twenty (20) plus years’ experience, providing solutions for Group and Health products and services. His clients range from brokers, financial institutions, shipping companies, health and educational organizations.

              Cleveland has worked in multi-currency jurisdictions, providing actuarial consultation to clients in Barbados, the Eastern Caribbean, Trinidad and Tobago, Panama, and Belize. His Group and Health Actuarial Function also supported these jurisdictions, which was his area of responsibility.

              Since the adoption of IFRS 9 by the IASB with effect 1st January 2018, Cleveland has been involved with the adoption and implementation of this new standard. This has been a very rewarding experience which ventured into the realms of IFRS 9 training, credit underwriting, credit risk management, and credit scorecard development and implementation.

              Cleveland obtained a Bachelor of Science degree in Special Mathematics from the University of the West Indies, Mona and is an Associate of the Society of Actuaries and is a qualified Health Insurance Advisor. Cleveland will be guiding the company’s clients through risk management analysis which will inform business decisions and data privacy strategies.
            </ReadMore>
          </div>

        </div>

        <div className="row my-1" >
          <div className="col-lg-3 col-md-4 our-team-responsive" >
            <img src={team3} className="img-ourTeam" />
            <div className="ring" >
            </div>
          </div>
          <div className="col-lg-9 col-md-8 mb-md-4 mb-lg-0" >
            <h3>Stephen Barnes  (BSc., MPhil.)</h3>
            <h5 className="accent" >Director of Governance and Finance </h5>
            <ReadMore>
              Stephen Barnes is an accomplished Financial Planning and Analysis (FP&A) professional with over 7 years’ experience in managing annual budgets for strategic business units and consolidated financials. His expertise lies in aligning stakeholders with strategic objectives, developing robust budget models, and conducting monthly forecasts. Leveraging financial modeling, VBA programming, and analytical skills, he has streamlined reporting processes, resolved business challenges, and assessed project profitability using capital budgeting techniques.
              In addition to his FP&A background, he possesses 3+ years’ experience in strategic analysis, conducting market research, competitor analysis, developing financial models, and extracting valuable insights from data. His dedication to continuous improvement has consistently contributed to the success of the organizations he served.
              Stephen Barnes has experience working with large and medium sized companies both in Jamaica and in other countries, providing substantive strategic insights and direction. He will guide the company’s clients through risk management, ensuring that the data strategies implemented will enable optimal business performance, using data performance analysis to guide this process.
              His education includes MPhil in Mathematics from the University of the West Indies, with a focus on Stochastic Models for Structured Financial Products. He also received a BSc. Actuarial Science (first class honors), University of the West Indies, as well as certifications from the Corporate Finance Institute which includes Business Intelligence and Data Analyst (BIDA) and is in the process of receiving his designation as a Chartered Financial Analyst (CFA).
            </ReadMore>
          </div>
        </div>
        <div className="row my-1" >
          <div className="col-lg-3 col-md-4 our-team-responsive" >
            <img src={team4} className="img-ourTeam" />
            <div className="ring" >
            </div>
          </div>
          <div className="col-lg-9 col-md-8 mb-md-4 mb-lg-0" >
            <h3>Rushane Barnes   (BSc., MSc. pending)</h3>
            <h5 className="accent" >Director of Information Technology  </h5>
            <ReadMore>
              With a stellar career as a Software Developer, Rushane brings an exceptional level of technical expertise. Rushane has played a pivotal role in developing an innovative solution for FATCA reporting, showcasing his proficiency in modern web technologies such as React. With his skills, he helped to design and implement an application that allows XML files to be read in a user-friendly manner. He was also involved in the creation of an application that reduced the processing time of customer information by 50%. He has worked on projects constructing a machine learning model that could predict the probability of a loan applicant.
              Rushane has a strong educational background, having earned a Bachelor of Science in Computer Science with a Minor in Economics from The University of the West Indies, Mona. He also completed certification in Cloud DevOps Engineer Nanodegree program at Udacity, and he is currently completing his Master of Science in Computer Science at The University of the West Indies, with a focus on data analytics.
            </ReadMore>
          </div>
        </div>
        <div className="row my-1" >
          <div className="col-lg-3 col-md-4 our-team-responsive" >
            <img src={team5} className="img-ourTeam" />
            <div className="ring" >
            </div>
          </div>
          <div className="col-lg-9 col-md-8 mb-md-4 mb-lg-0" >
            <h3>Tamara Minott   (BSc., ACCA)</h3>
            <h5 className="accent" >Independent Board Member</h5>
            <ReadMore>
              Tamara Minott possesses a wealth of experience in accounting, financial management and corporate services, working with leading organizations as an auditor, accountant and financial consultant. She obtained a Bachelor of Science degree in Accounting and Management (first class honours) at the University of the West Indies, Mona. She is also a qualified Chartered Accountant and received awards for top performance in selected ACCA examinations within the region.
              Tamara contributes her multi-jurisdictional expertise to the company,  providing guidance on international best practices across lines of services.
            </ReadMore>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowOurTeam
