import React, { useState } from 'react'
import { Link } from 'react-router-dom';


const SecurityServices = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="background-service-home">
        <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">The Security and Technical Privacy Services</h1>
      </div>
      <div className='container py-5'>

        <div className='row'>
          <div className='col-md-8 mb-4'>
            <h2 class="accent font mb-3">The Security and Technical Privacy Services  </h2>
            <p className='pe-lg-5 text_justify'>Security and Technical Privacy Services are dedicated to fortifying an organization's data protection mechanisms through the implementation of advanced security measures and technology-driven privacy solutions. This comprehensive approach encompasses the assessment of current security infrastructure, identification of vulnerabilities, and the deployment of robust security protocols to safeguard sensitive data against unauthorized access, breaches, and cyber threats. By integrating cutting-edge technology and privacy-enhancing tools, these services ensure that data is not only secure but also handled in compliance with applicable privacy laws and regulations. Tailored to address specific organizational needs, Security and Technical Privacy Services play a critical role in establishing a resilient and trustworthy data protection framework, thereby reinforcing an organization's commitment to safeguarding personal and confidential information.</p>
          </div>
          <div className='col-md-4 mb-4'>
            <div className='card card_shodow1 border-0'>
              <h5 className='card-header bg_data_ul py-3 '>Data Privacy Services</h5>
              <div className='card-body'>
                <ul className='mb-0'>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services' className='clr_data_list'>Initial Data Privacy Programme Implementation </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-officer' className='clr_data_list'>The Outsourced Data Protection Officer services </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-advisory' className='clr_data_list'>The General Support, Consulting and Advisory Service </Link></li>
                  <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-process' className='clr_data_list'>Data Privacy Process Re-engineering and Alignment </Link></li>
                  <li className='f14  marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-organisational' className='clr_data_list'>Organisational and Executive Privacy Training Development </Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurityServices
