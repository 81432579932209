import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { IoSend } from 'react-icons/io5';
import Alert from '@mui/material/Alert';
import io from 'socket.io-client';

const ChatWindow = () => {
    const [chatVisible, setChatVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [senderID, setSenderID] = useState('');
    const [socket, setSocket] = useState(null);
    const [inputSaved, setInputSaved] = useState(false);
    const [placeholder, setPlaceholder] = useState('Please enter your name');
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const socketInstance = io.connect('http://103.27.234.68:6020');
        setSocket(socketInstance);

        socketInstance.on('message', (msg) => {
            setMessages(prevMessages => [...prevMessages, msg]);
        });

        return () => {
            socketInstance.disconnect();
        };
    }, []);

    useEffect(() => {
        const storedID = localStorage.getItem('senderID');
        if (storedID) {
            setSenderID(storedID);
        } else {
            const newSenderID = generateSenderID();
            setSenderID(newSenderID);
            localStorage.setItem('senderID', newSenderID);
        }
    }, []);

    useEffect(() => {
        const storedUsername = localStorage.getItem('userName');
        const storedEmail = localStorage.getItem('userEmail');
        if (storedUsername && storedEmail) {
            setUsername(storedUsername);
            setEmail(storedEmail);
            setInputSaved(true);
            setPlaceholder('Enter a message');
        }
    }, []);

    const generateSenderID = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const toggleMenu = () => {
        setChatVisible(!chatVisible);
    };

    const handleNameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleButtonClick = () => {
        if (!username || !email) {
            alert('Please enter both name and email.');
            return;
        }
        localStorage.setItem('userName', username);
        localStorage.setItem('userEmail', email);
        setInputSaved(true);
        setPlaceholder('Enter a message');
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendClick = () => {
        if (message.trim() !== '') {
            const msg = {
                user: username,
                email: email,
                senderID: senderID,
                message: message.trim()
            };
            socket.emit('message', msg);
            setMessage('');
        }
    };

    return (
        <div>
            <div id='chat-box' className={chatVisible ? '#chat-box active' : null}>
                <div className='chat-button'>
                    <p className='m-0' onClick={toggleMenu}><IoIosArrowUp id='chat-icon' className={chatVisible ? '#chat-icon active' : null} />Have a query?</p>
                </div>
                <div className='chat-body'>
                    {inputSaved ? (
                        <div>
                            {messages.map((msg, index) => (
                                <div key={index} className={msg.user === username ? 'd-flex flex-row justify-content-end chat-my align-items-baseline' : 'd-flex flex-row justify-content-start chat-other align-items-baseline'}>
                                    <div>
                                        <p className={msg.user === username ? 'chat-msg m-0 bg-gray text-white' : 'chat-msg m-0'}>
                                            {msg.user === username ? 'You' : msg.user}: {msg.message}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='px-2'>
                            <div className='mb-3'>
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    className='form-control'
                                    value={username}
                                    onChange={handleNameChange}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    className='form-control'
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                            <button className='btn btn-warning text-white' onClick={handleButtonClick}>Submit</button>
                        </div>
                    )}
                </div>
                {inputSaved && (
                    <div className='chat-footer'>
                        <div className="input-group input-group-sm rounded-4 dark">
                            <input
                                type="text"
                                id="inputGroup-sizing-sm"
                                className="form-control chat-input ms-3"
                                placeholder="Enter your message"
                                value={message}
                                onChange={handleMessageChange}
                            />
                            <button
                                className="btn sent-btn"
                                type="button"
                                id="button-addon2"
                                onClick={handleSendClick}
                            >
                                <IoSend className='sent-icon' />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ChatWindow;
